import React from "react";
import "./App.css";
import Main from "pages/MainComponent";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Store from "store/Store";
import { ToastContainer } from "react-toastify";
import "./App.css"
import "./assets/css/style.css"


function App() {

    const theme = createTheme({
        palette: {
          primary: {
            main:"#010b40",
            light:"#102282",
            dark:"#070d2e"
          },
        },
        components: {
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  fontSize: "0.7em",
                  fontWeight:"400",
                  color: "white",
                  textShadow:"none",
                  backgroundColor: "#125a96"
                },
                arrow: {
                    color: "#125a96",
                  },
              }
            }
          },
      });
// const talha=""
    return (
        <Provider store={Store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
                <ToastContainer />
            </ThemeProvider>
        </Provider>
    );
}

export default App;
